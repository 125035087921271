<template>
    <div v-if="$can('conciliacion.menu.consultar.arqueo.de.caja')">
        <ModuloReporteCaja
            :monedasimbolo="config.monedasimbolo" 
            :almacenid="config.almacenid"
            :habilitararqueocaja="config.habilitararqueocaja"
            :ventadecimal="config.ventadecimal"
        />
    </div>
</template>

<script>
    import ModuloReporteCaja from './Modulos/ModuloReporteCaja.vue'

    export default {

        props: [
            'usuario',
            'config'
        ],

        components: {
            ModuloReporteCaja
        }
    }
</script>