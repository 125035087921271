<template>
    <div>
		<a-row type="flex" :gutter="[2, 5]">
            <a-divider orientation="left">
				<span style="color:black; font-size:18px;">Arqueo de Cajas</span> <span style="color:#9C9C9C; font-size:12px;">Visualiza tus comprobantes de arqueo</span>
			</a-divider>

            <a-col flex="1 1 100px">
                <a-button 
                    block 
                    type="primary" 
                    @click="openMenu('arqueocaja')" 
                    v-if="$can('conciliacion.menu.arqueo.de.caja') && config.habilitararqueocaja === 'ACTIVADO'"
                    >
                    <a-icon type="plus" /> Nuevo Arqueo
                </a-button>
            </a-col>

            <a-col flex="1 1 100px">
                <a-button block type="dashed" @click="openModalConsultaOpciones()"><a-icon type="search" /> Consultar Opciones</a-button>
            </a-col>

            <a-col flex="1 1 200px">
				<a-date-picker 
                    :value="utils.getDateFormat(consulta.fecha_inicio)" 
                    @change="onChangeFechaInicio"
                    placeholder="Fecha Inicio"
                    :locale="locale" 
                    style="width: 100%"
                />
            </a-col>

            <a-col flex="1 10 200px">
				<a-date-picker 
                    :value="utils.getDateFormat(consulta.fecha_final)"
                    @change="onChangeFechaFinal"
                    placeholder="Fecha Final"
                    :locale="locale"
                    style="width: 100%"
                />
			</a-col>

            <a-col flex="1 1 350px">
                <a-input-search placeholder="Buscar por codigo" @search="onSearch">
                    <a-button type="primary" slot="enterButton">
                        Buscar
                    </a-button>
                </a-input-search>
            </a-col>

            <a-col :span="1">
                <a-button @click="obtenerListadoActual">
                    <a-icon type="reload" />
                </a-button>
            </a-col>
        </a-row>
        
        <br>
	    
        <a-table 
			:columns="columns" 
			:data-source="arqueocajas" 
			:pagination="paginate"
			:loading="loading"
			:ellipsis="true"
			size="small"
			:rowKey="record => record.arqueocaja_id"
            :scroll="{ x: 980 }"
			@change="handlePagination"
			>

			<template slot="estado" slot-scope="item">
                <a-tag color="green" v-if="item.operacionCaja === 'ABIERTO'">
                    {{ item.operacionCaja }}
                </a-tag>
                <a-tag color="red" v-if="item.operacionCaja === 'CERRADO'">
                    {{ item.operacionCaja }}
                </a-tag>
            </template>	

			<template slot="sucursal" slot-scope="item">
				{{ item.almacen.nombre }}
			</template>
			
			<template slot="fechaApertura" slot-scope="item">
				{{ utils.formatDate(item.fechaApertura) }}
			</template>

			<template slot="fechaCierre" slot-scope="item">
				{{ (item.fechaCierre) ? utils.formatDate(item.fechaCierre) : '' }}
			</template>

			<span slot="expandedRowRender" slot-scope="item" style="margin: 0">
				<span style="font-weight:700; font-size:17px;">DATOS DE REGISTRO</span>
				<a-row>
					<a-col :span="12">
						<a-list size="small" bordered><a-list-item>ID:</a-list-item></a-list>
						<a-list size="small" bordered><a-list-item>FECHA DE CIERRE PROGRAMADO:</a-list-item></a-list>
						<a-list size="small" bordered><a-list-item>USUARIO:</a-list-item></a-list>
						<a-list size="small" bordered><a-list-item>NOTA:</a-list-item></a-list>
						<a-list size="small" bordered><a-list-item>OBSERVACION:</a-list-item></a-list>
					</a-col>
					<a-col :span="12">
						<a-list size="small" bordered><a-list-item>{{ item.arqueocaja_id }}</a-list-item></a-list>
						<a-list size="small" bordered><a-list-item>{{ (item.fechaCierreProgramado) ? utils.formatDate(item.fechaCierreProgramado) : 'Sin Fecha Programado' }}</a-list-item></a-list>
						<a-list size="small" bordered><a-list-item>{{ item.usuario.usuario }}</a-list-item></a-list>
						<a-list size="small" bordered><a-list-item>{{ item.nota }}</a-list-item></a-list>
						<a-list size="small" bordered><a-list-item>{{ (item.observacion) ? item.observacion : 'SIN OBSERVACIONES' }}</a-list-item></a-list>
					</a-col>
				</a-row>
			</span>

			<template slot="action" slot-scope="item">
				<a-dropdown>
					<a-menu slot="overlay">

                        <a-menu-item v-if="item.operacionCaja === 'CERRADO'" :key="buttonKeyVerReporte">
                            <a-tooltip placement="topLeft">
                                <template slot="title">
                                    <span>{{ item.codigo }}</span>
                                </template>
                                <a href="#" @click.prevent="reporteCarta(item.arqueocaja_id)"><a-icon type="file-pdf" :style="{ fontSize: '18px', color: '#fa1d03' }"/> &nbsp;<strong>Comprobante PDF</strong></a>
                            </a-tooltip>
                        </a-menu-item>

					</a-menu>
					<a-button> Acciones <a-icon type="down" /> </a-button>
				</a-dropdown>
			</template> 
		</a-table>

        <a-modal
            v-model="modalConsultaOpciones"
            :title="false"
            :closable="false"
            :destroyOnClose="false"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="350"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <a-row type="flex">
                    <a-col flex="1 1 200px" v-if="$can('conciliacion.consultar.arqueo.caja.por.sucursal')">
                        <label for="almacen" class="control-label">Sucursal:</label>
                        <a-select size="large" :value="consulta.almacen_id" @change="onChageAlmacen" style="width: 100%">
                            <a-select-option v-for="(value, index) in almacenes" :key="index" :value="value.almacen_id"> {{ value.nombre }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 10 200px" v-if="$can('conciliacion.consultar.arqueo.caja.por.usuario')">
                        <label for="proveedor" class="control-label">Usuario:</label>
                        <a-select size="large" :value="consulta.usuario_id" @change="onChageUsuario" style="width: 100%">
                            <a-select-option v-for="(value, index) in usuarios" :key="index" :value="value.usuario_id"> {{ value.usuario }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 1 200px">
                        <label for="proveedor" class="control-label">Estado Caja:</label>
                        <a-select size="large" :value="consulta.estadoCaja" @change="onChageEstadoCaja" style="width: 100%">
                            <a-select-option v-for="(value, index) in lista_estado_caja" :key="index" :value="value.nombre"> {{ value.nombre }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 1 200px">
                        <label class="control-label">Accion:</label>
                        <a-button size="large" type="primary" block @click="obtenerListadoActual">
                            <a-icon type="reload" /> Reestablecer Opciones Busqueda
                        </a-button>
                    </a-col>
                </a-row>
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="modalConsultaOpciones = false">
                    Cerrar
                </a-button>
            </template>
        </a-modal>

        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1040;">
            <div style="margin-top: 250px;">
                <vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
                <br><br>
                <center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
            </div>
        </div>
    </div>
</template>

<script>
	const columns = [
        { 
            id: 'arqueocaja_id',
            title: 'Id', 
            dataIndex: 'arqueocaja_id',
            key: 'arqueocaja_id',
            width: '10%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'arqueocaja_id',
            title: 'Codigo', 
            dataIndex: 'codigo',
            key: 'codigo',
            width: '15%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
		{ 
            id: 'arqueocaja_id',
            title: 'Sucursal', 
            dataIndex: '',
			scopedSlots: { customRender: 'sucursal' },
            key: 'sucursal',
            width: '15%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
		{ 
            id: 'arqueocaja_id',
            title: 'Importe', 
            dataIndex: 'importeTotal',
            key: 'importeTotal',
            width: '15%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
		{ 
            id: 'arqueocaja_id',
            title: 'Entregado', 
            dataIndex: 'efectivoEntregado',
            key: 'efectivoEntregado',
            width: '15%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
		{ 
            id: 'arqueocaja_id',
            title: 'Diferencia', 
            dataIndex: 'diferencia',
            key: 'diferencia',
            width: '15%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
		{ 
            id: 'arqueocaja_id',
            title: 'Estado', 
            dataIndex: '',
			scopedSlots: { customRender: 'estado' },
            key: 'estado',
            width: '12%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'arqueocaja_id',
            title: 'F.Apertura', 
            dataIndex: '',
            key: 'fechaApertura',
            scopedSlots: { customRender: 'fechaApertura' },
            width: '15%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'arqueocaja_id',
            title: 'F.Cierre', 
            dataIndex: '',
            key: 'fechaCierre',
            scopedSlots: { customRender: 'fechaCierre' },
            width: '15%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'arqueocaja_id',
            title: 'Acciones', 
            dataIndex: '',
            scopedSlots: { customRender: 'action' },
            width: '15%'
        }
    ]

    import router from '@/config/router'
	import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
	import moment from 'moment'
	import 'moment/locale/es'
	import * as utils from '@/utils/utils'
	import { mapGetters, mapActions } from 'vuex'

    export default {

        props: [
			'monedasimbolo',
			'almacenid',
			'habilitararqueocaja'
		],

        data () {
            return {
				dateFormat: 'YYYY-MM-DD',
				moment,
				locale,
				utils,
				columns,
                consulta: {
                    almacen_id: 'Seleccionar',
					usuario_id: 'Seleccionar',
					estadoCaja: 'Seleccionar',
                    fecha_inicio: '',
                    fecha_final: '',
                    keyword: ''
                },
				buttonKeyVerReporte: 100,
				control: {
					arqueocajaID: '',
					codigoArqueoCaja: '',
					almacen: '',
					usuario: '',
					estadoCaja: '',
					fechaApertura: '',
					fechaCierreProgramado: '',
					fechaCierre: '',
					lista_ingresos: [],
					lista_egresos: [],
					subtotal_ingresos: '',
					subtotal_egresos: '',
					total: ''
				},
				arqueocaja: {
					importe: 0,
					entregado: '',
					diferencia: 0,
					saldo: '0.00', 
					observacion: 'SIN OBSERVACIÓN'
				},
				showModalFechaCierre: false,
                modalConsultaOpciones: false,
				spinnerloading: false,
				lista_estado_caja: [
					{ id: '0', nombre: 'Seleccionar' },
					{ id: '1', nombre: 'ABIERTO' },
					{ id: '2', nombre: 'CERRADO' }
				],
				lista_arqueo_caja: [],
				ingreso_total: '0.00',
				entregado_total: '0.00',
				diferencia_total: '0.00',
				paginate: {
                    pageSize: 10,
                    current: 1,
                    total: 0,
                    field: 'arqueocaja_id',
                    order: 'descend'
                },
				loading: false
            }
        },

        created () {
            this.getAlmacenes()
			this.getTipoUsuarios()
			this.paginarResultados()
        },

		computed: {
			...mapGetters('auth', ['user']),
			...mapGetters('almacen', ['almacenes']),
			...mapGetters('usuario', ['usuarios']),
			...mapGetters('arqueocaja', ['arqueocajas', 'listado']),
            ...mapGetters('configuracion', ['config'])
		},

        methods: {
			...mapActions('almacen', ['getAlmacenListado']),
			...mapActions('usuario', ['getUsuarioListar']),
			...mapActions('arqueocaja', ['getArqueoCajaPaginate']),

            openMenu (name) {
				router.push({ name: name })
			},

            openModalConsultaOpciones () {
                this.modalConsultaOpciones = true
            },

            getAlmacenes () {
                this.getAlmacenListado()
				.then(response => {
					this.consulta.almacen_id = this.user.almacenID.toString()
				})
				.catch(error => {
					utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
				})
            },
			
			getTipoUsuarios () {
                this.getUsuarioListar()
				.then(response => {
                    this.consulta.usuario_id = this.user.usuario_id
                })
				.catch(error => {
					utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
				})
            },

			onChangeFechaInicio (date, dateString) {
				this.consulta.fecha_inicio = dateString
			},

            onChangeFechaFinal (date, dateString) {
                this.spinnerloading = true
				this.consulta.fecha_final = dateString
                this.paginarResultados()
			},

			onChageAlmacen (value) {
                this.spinnerloading = true
                this.consulta.almacen_id = value
                this.paginarResultados()
            },

			onChageUsuario (value) {
                this.spinnerloading = true
                this.consulta.usuario_id = value
                this.paginarResultados()
            },

			onChageEstadoCaja (value) {
                this.spinnerloading = true
				this.consulta.estadoCaja = value
                this.paginarResultados()
			},

            handlePagination (paginate, filters, sorter) {
                this.paginate = {
                    pageSize: paginate.pageSize,
                    current: paginate.current,
                    total: paginate.total,
                    field: (sorter.field) ? sorter.field : 'arqueocaja_id',
                    order: (sorter.order) ? sorter.order : 'descend'
                }
                this.paginarResultados()
            },

            changePaginate (data) {
                this.paginate = {
                    pageSize: data.per_page,
                    current: data.current_page,
                    total: data.total,
                    field: 'arqueocaja_id',
                    order: 'descend'
                }
            },

            paginarResultados () {
				const consultaAlmacen = (this.consulta.almacen_id === 'Seleccionar') ? this.user.almacenID : this.consulta.almacen_id
                const consultaUsuario = (this.consulta.usuario_id === 'Seleccionar') ? '' : this.consulta.usuario_id

				this.consulta.almacen_id = (this.$can('conciliacion.consultar.arqueo.caja.por.sucursal')) ? consultaAlmacen : this.user.almacenID
				this.consulta.usuario_id = (this.$can('conciliacion.consultar.arqueo.caja.por.usuario')) ? consultaUsuario : this.user.usuario_id

                this.getArqueoCajaPaginate({ ...this.paginate, ...this.consulta })
                .then(response => {
                    this.changePaginate(response.data.meta)
                    this.spinnerloading = false
                }).catch(error => {
                    this.loading = false
                    utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
                })
            },

            clearData () {
                this.consulta.almacen_id = this.user.almacenID.toString()
				this.consulta.usuario_id = this.user.usuario_id
                this.consulta.fecha_inicio = ''
                this.consulta.fecha_final = ''
                this.consulta.estadoCaja = 'Seleccionar'
                this.consulta.keyword = ''
            },

            obtenerListadoActual (value) {
                this.spinnerloading = true
                this.clearData()
                this.paginarResultados()
            },

            onSearch (value) {
                this.spinnerloading = true
                this.consulta.keyword = value
                this.paginarResultados()
            },

            reporteCarta (arqueocajaID) {
                utils.openRecibo(`/reporte/arqueocaja/reporte/carta/${arqueocajaID}`)
            }
        }
    }
</script>